import React, {useEffect, useRef, useState} from 'react';
import './App.css';

// RCPT_YR	접수연도
// CGG_CD	자치구코드
// CGG_NM	자치구명
// STDG_CD	법정동코드
// STDG_NM	법정동명
// LOTNO_SE	지번구분
// LOTNO_SE_NM	지번구분명
// MNO	본번
// SNO	부번
// BLDG_NM	건물명
// CTRT_DAY	계약일
// THING_AMT	물건금액(만원)
// ARCH_AREA	건물면적(㎡)
// LAND_AREA	토지면적(㎡)
// FLR	층
// RGHT_SE	권리구분
// RTRCN_DAY	취소일
// ARCH_YR	건축년도
// BLDG_USG	건물용도
// DCLR_SE	신고구분
// OPBIZ_RESTAGNT_SGG_NM	신고한 개업공인중개사 시군구명

const Result = {
  RCPT_YR: '',
  CGG_CD: '',
  CGG_NM: '',
  STDG_CD: '',
  STDG_NM: '',
  LOTNO_SE: '',
  LOTNO_SE_NM: '',
  MNO: '',
  SNO: '',
  BLDG_NM: '',
  CTRT_DAY: '',
  THING_AMT: '',
  ARCH_AREA: '',
  LAND_AREA: '',
  FLR: '',
  RGHT_SE: '',
  RTRCN_DAY: '',
  ARCH_YR: '',
  BLDG_USG: '',
  DCLR_SE: '',
  OPBIZ_RESTAGNT_SGG_NM: ''
}

let param = {
    START_INDEX:1,
    END_INDEX:5,
    RCPT_YR: ' ',
    CGG_NM: '동작구',
    MNO: ' ',
    SNO: ' ',
    BLDG_NM: ' ',
    CTRT_DAY: ' '
  }

const KakaoMap = () => {
  const mapContainer = useRef(null); // 맵이 들어갈 div
  const [results, setResults] = useState([]);
  const [loadMessage, setLoadMessage] = useState('');


  useEffect(() => {
    // 카카오맵 API 로드
    const { kakao } = window;

    const options = {
      center: new kakao.maps.LatLng(37.481199, 126.975518), // 초기 맵의 중심 좌표 (서울시청)
      level: 3, // 줌 레벨
    };

    // 맵 생성
    const map = new kakao.maps.Map(mapContainer.current, options);
    // let infowindow = new kakao.maps.InfoWindow({zindex:1});
    // 주소-좌표 변환 객체를 생성합니다
    // var geocoder = new kakao.maps.services.Geocoder();

    // 마커 추가
    const markerPosition = new kakao.maps.LatLng(37.481199, 126.975518);
    const marker = new kakao.maps.Marker({
      position: markerPosition,
      level: 3
    });
    marker.setMap(map);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 제거
      mapContainer.current.innerHTML = '';
    };
  }, []);

  useEffect(() => {
    // 공공 API 조회\
    getInfo(1,5,2024,'동작구', ' ', ' ', ' ', ' ' )
  }, []);

  const getInfo = (START_INDEX, END_INDEX, RCPT_YR, CGG_NM, MNO, SNO, BLDG_NM, CTRT_DAY) => {
    // [O]START_INDEX	INTEGER(필수)	요청시작위치	정수 입력 (페이징 시작번호 입니다 : 데이터 행 시작번호)
    // [O]END_INDEX	INTEGER(필수)	요청종료위치	정수 입력 (페이징 끝번호 입니다 : 데이터 행 끝번호)
    // [O]RCPT_YR	STRING(선택)	접수연도	YYYY
    // [X]CGG_CD	STRING(선택)	자치구코드	5자리 정수
    // [O]CGG_NM	STRING(선택)	자치구명	문자열
    // [X]STDG_CD	STRING(선택)	법정동코드	5자리 정수
    // [X]LOTNO_SE	STRING(선택)	지번구분	1:대지,2:산,3:블럭
    // [X]LOTNO_SE_NM	STRING(선택)	지번구분명	문자열
    // [O]MNO	STRING(선택)	본번	4자리 정수
    // [O]SNO	STRING(선택)	부번	4자리 정수
    // [O]BLDG_NM	STRING(선택)	건물명	문자열
    // [O]CTRT_DAY	STRING(선택)	계약일	YYYYMMDD
    // [X]BLDG_USG	STRING(선택)	건물용도	아파트/단독다가구/연립다세대/오피스텔 택1
    const apiKey = '796271437a70757331333072664e5469';
    fetch(`http://openapi.seoul.go.kr:8088/${apiKey}/json/tbLnOpendataRtmsV/${START_INDEX}/${END_INDEX}/${RCPT_YR}/ /${CGG_NM}/${10700}/ / /${MNO}/${SNO}/${BLDG_NM}/${CTRT_DAY}/ /`)
    .then((response) => response.json())
    .then(json => {
      console.log(json)
      setLoadMessage(json.tbLnOpendataRtmsV.RESULT.MESSAGE);
      setResults(json.tbLnOpendataRtmsV.row)
    })
    ;
  }

  const handleChangeParam = (name, val) =>{
    param[name] = val ? val : ' ';
  }

  const handleClickSearch = () => {
    setLoadMessage('조회중입니다..')
    const {
      START_INDEX,
      END_INDEX,
      RCPT_YR,
      CGG_NM,
      MNO,
      SNO,
      BLDG_NM,
      CTRT_DAY
    } = param;

    getInfo(
      START_INDEX,
      END_INDEX,
      RCPT_YR,
      CGG_NM,
      MNO,
      SNO,
      BLDG_NM,
      CTRT_DAY
    );
  }

  return (
    <div
      style={{
        display: "flex",
        overflow: "hidden",
        padding: "2%"
      }}
    >
      {/* 지도 영역 */}
      <div
        ref={mapContainer}
        style={{
          minWidth: "50%",
          height: "90vh",
          flexBasis: "70%",
          border: "solid 1px"
        }}
      />

      {/* 입력 및 테이블 영역 */}
      <div
        style={{
          maxWidth: "50%",
          flexBasis: "30%",
          padding: "1%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "5px"
        }}
      >

        {/* 입력 필드 */}
        <div>접수연도</div>
        <input type="text" onChange={(val) => handleChangeParam('RCPT_YR', val.target.value)}/>
        <div>본번</div>
        <input type="text" onChange={(val) => handleChangeParam('MNO', val.target.value)}/>
        <div>부번</div>
        <input type="text" onChange={(val) => handleChangeParam('SNO', val.target.value)}/>
        <div>건물명</div>
        <input type="text" onChange={(val) => handleChangeParam('BLDG_NM', val.target.value)}/>
        <div>계약일</div>
        <input type="text" onChange={(val) => handleChangeParam('CTRT_DAY', val.target.value)}/>
        <div style={{display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", width: "70%"}}>
          <div style={{fontSize: "11px", flexBasis: "15%"}}>Start OFFSET</div>
          <input style={{flexBasis: "5%"}} type="text" onChange={(val) => handleChangeParam('START_INDEX', val.target.value)} defaultValue={1}/>
          <div style={{fontSize: "11px", flexBasis: "15%"}}>End OFFSET</div>
          <input style={{flexBasis: "5%"}} type="text" onChange={(val) => handleChangeParam('END_INDEX', val.target.value)} defaultValue={5}/>
        </div>

        <button onClick={handleClickSearch}>조회</button>
        {/* 테이블 스크롤 영역 */}
        <h3>결과 <br/> <span style={{fontSize: "9px", color: "green"}}>{loadMessage}</span></h3>
        <div
          style={{
            flexGrow: 1, // 남은 공간을 채움
            maxWidth: "100%",
            minHeight: "300px",
            maxHeight: "300px",
            overflowX: "auto",
            overflowY: "auto", // 필요 시 세로 스크롤도 추가
          }}
        >
          <table
            style={{
              minWidth: "1000px", // 테이블 최소 너비 설정
              borderCollapse: "collapse",
            }}
          >
            <thead>
            <tr>
              <th>접수연도</th>
              <th>자치구코드</th>
              <th>자치구명</th>
              <th>법정동코드</th>
              <th>법정동명</th>
              <th>지번구분</th>
              <th>지번구분명</th>
              <th>본번</th>
              <th>부번</th>
              <th>건물명</th>
              <th>계약일</th>
              <th>물건금액(만원)</th>
              <th>건물면적(㎡)</th>
              <th>토지면적(㎡)</th>
              <th>층</th>
              <th>권리구분</th>
              <th>취소일</th>
              <th>건축년도</th>
              <th>건물용도</th>
              <th>신고구분</th>
              <th>신고한 개업공인중개사 시군구명</th>
            </tr>
            </thead>
            <tbody>
            {
              results.map(r => (
                <tr>
                  <td>{r['RCPT_YR']}</td>
                  <td>{r['CGG_CD']}</td>
                  <td>{r['CGG_NM']}</td>
                  <td>{r['STDG_CD']}</td>
                  <td>{r['STDG_NM']}</td>
                  <td>{r['LOTNO_SE']}</td>
                  <td>{r['LOTNO_SE_NM']}</td>
                  <td>{r['MNO']}</td>
                  <td>{r['SNO']}</td>
                  <td>{r['BLDG_NM']}</td>
                  <td>{r['CTRT_DAY']}</td>
                  <td>{r['THING_AMT']}</td>
                  <td>{r['ARCH_AREA']}</td>
                  <td>{r['LAND_AREA']}</td>
                  <td>{r['FLR']}</td>
                  <td>{r['RGHT_SE']}</td>
                  <td>{r['RTRCN_DAY']}</td>
                  <td>{r['ARCH_YR']}</td>
                  <td>{r['BLDG_USG']}</td>
                  <td>{r['DCLR_SE']}</td>
                  <td>{r['OPBIZ_RESTAGNT_SGG_NM']}</td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>


  );
};

export default KakaoMap;

